<template>
  <v-container id="dashboard" fluid tag="section" v-if="statistics != null">
    <!-- <h1 style="text-align: center; font-size: xxx-large">
      Gluggler | Online Liquor Delivery
    </h1> -->

    <v-row>
      <v-col cols="3" lg="3">
        <base-material-stats-card color="red" icon="mdi-alert-octagon" title="" v-model="statistics.pendingOrder"
          sub-text="Pending Orders" />
      </v-col>

      <v-col cols="3" lg="3">
        <base-material-stats-card color="primary" icon="mdi-truck-delivery" title="" v-model="statistics.activeOrder"
          sub-text="Active Orders" />
      </v-col>

      <v-col cols="3" lg="3">
        <base-material-stats-card color="success" icon="mdi-package-check" title="" v-model="statistics.completedOrder"
          sub-text="Delievered Orders" />
      </v-col>

      <v-col cols="3" lg="3">
        <base-material-stats-card color="orange" icon="mdi-sale" title="" v-model="statistics.totalSale"
          sub-icon-color="red" sub-text="Todays Total Sale" />
      </v-col>

      <v-col cols="6" lg="6">
        <base-material-chart-card :data="statistics.last7DaysSales.data" :options="salesOption"
          :responsive-options="responsiveOptions" color="success" hover-reveal type="Bar">
          <!-- <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" color="info" icon v-on="on">
                  <v-icon color="info"> mdi-refresh </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" light icon v-on="on">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template> -->

          <h4 class="card-title font-weight-light mt-2 ml-2">Daily sales</h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Daily sales of last seven days
          </p>

          <!-- <template v-slot:actions>
            <v-icon class="mr-1" small> mdi-clock-outline </v-icon>
            <span class="text-caption grey--text font-weight-light"
              >updated 10 minutes ago</span
            >
          </template> -->
        </base-material-chart-card>
      </v-col>
      <v-col cols="6" lg="6" v-if="statistics.last7DaysTotalOrders.data">
        <base-material-chart-card :data="statistics.last7DaysTotalOrders.data" :options="orderOptions"
          :responsive-options="responsiveOptions" color="danger" hover-reveal type="Bar">
          <!-- <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" color="info" icon v-on="on">
                  <v-icon color="info"> mdi-refresh </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" light icon v-on="on">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template> -->

          <h4 class="card-title font-weight-light mt-2 ml-2">Total orders</h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Total orders of last seven days
          </p>

          <!-- <template v-slot:actions>
            <v-icon class="mr-1" small> mdi-clock-outline </v-icon>
            <span class="text-caption grey--text font-weight-light"
              >updated 10 minutes ago</span
            >
          </template> -->
        </base-material-chart-card>
      </v-col>
      <v-row>
        <v-col cols="6" lg="6" v-if="storePayoutData" class="ml-3">
          <base-material-chart-card :data="storePayoutData" :options="storePayoutOptions"
            :responsive-options="responsiveOptions" color="primary" hover-reveal type="Bar">
            <!-- <template v-slot:reveal-actions>
              <v-tooltip bottom>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn v-bind="attrs" color="info" icon v-on="on">
                    <v-icon color="info"> mdi-refresh </v-icon>
                  </v-btn>
                </template>
  
                <span>Refresh</span>
              </v-tooltip>
  
              <v-tooltip bottom>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn v-bind="attrs" light icon v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
  
                <span>Change Date</span>
              </v-tooltip>
            </template> -->

            <h4 class="card-title font-weight-light mt-2 ml-2">Daily Payouts</h4>

            <p class="d-inline-flex font-weight-light ml-2 mt-1">
              Total daily payouts for today
            </p>

            <!-- <template v-slot:actions>
              <v-icon class="mr-1" small> mdi-clock-outline </v-icon>
              <span class="text-caption grey--text font-weight-light"
                >updated 10 minutes ago</span
              >
            </template> -->
          </base-material-chart-card>
        </v-col>
        <v-col cols="6" lg="6" class="payout-table">
          <store-wise-orders />
        </v-col>
      </v-row>

      <!-- <tipalti-payments/> -->
      <active-pending-orders />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DashboardDashboard",
  components: {
    ActivePendingOrders: () => import("./pages/Orders/ActivePendingOrders.vue"),
    StoreWiseOrders: () => import("./pages/Orders/StoreWisePayouts.vue"),
    // TipaltiPayments: () => import("./pages/Tipalti/TipaltiPayments.vue")
  },
  data() {
    return {
      statistics: null,
      storePayoutData: [],
      orderOptions: {
        axisX: {
          showGrid: false,
        },
        low: 0,
        high: 1000,
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      storePayoutOptions: {
        axisX: {
          showGrid: false,
        },
        low: 0,
        high: 200,
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      salesOption: {
        axisX: {
          showGrid: false,
        },
        low: 0,
        high: 1000,
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      responsiveOptions: [
        [
          "screen and (max-width: 640px)",
          {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0];
              },
            },
          },
        ],
      ],
    };
  },

  methods: {
    complete(index) {
      this.list[index] = !this.list[index];
    },
    getPayoutStatistics() {
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "ordersByStore" + "?page=" + this.page, {
          driverId: null,
          customerId: null,
          orderStatus: this.orderStatus,
        })
        .then((response) => {
          if (response.status == 200) {
            this.TotalDailyPayout = response.data.orders;
            this.parseStorePayoutData(response.data.orders); // Corrected method invocation
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    parseStorePayoutData(response) {

      const storeNames = [];
      const storePayouts = [];

      response.forEach((store) => {
        storeNames.push(store.store_name);
        storePayouts.push(parseFloat(store.estimated_payout)); 
      });


      this.storePayoutData = {
        labels: storeNames,
        series: [storePayouts],
      };

    },

    getStatistics() {
      axios.get(process.env.VUE_APP_API_BASE_URL + "statsChart").then((response) => {
        this.statistics = response.data;
        let highestOrder = 0;
        let last7DaysTotalOrders = this.statistics.last7DaysTotalOrders.data.series[0];
        for (var i = 0; i < last7DaysTotalOrders.length; i++) {
          if (highestOrder < last7DaysTotalOrders[i]) {
            highestOrder = last7DaysTotalOrders[i];
          }
        }
        this.orderOptions.high = highestOrder;
        let highestSales = 0;
        let last7DaysSales = this.statistics.last7DaysSales.data.series[0];
        for (var i = 0; i < last7DaysSales.length; i++) {
          if (highestSales < last7DaysSales[i]) {
            highestSales = last7DaysSales[i];
          }
        }
        this.salesOption.high = highestSales;
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.getStatistics();
      this.getPayoutStatistics();
    }, 1000);
  },
};
</script>
<style>
.payout-table {
    margin: inherit;
}

</style>